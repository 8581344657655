@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;700;800&display=swap');

body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}

.maplibregl-ctrl-attrib {
  font-family: "Nunito Sans", "Roboto", sans-serif;
  font-size: 0.8em;
}

.map-tooltip {
  z-index: 4;
}

.map-tooltip .maplibregl-popup-content {
  padding: 4px 8px;
  width: 250px;
}

.map-tooltip .maplibregl-popup-content h3,
.map-tooltip .maplibregl-popup-content p {
  margin: 0;
}

@keyframes loading-dots {
  0% {
    margin-bottom: 0px;
  }
  16.5% {
    margin-bottom: 16px;
  }
  33% {
    margin-bottom: 0px;
  }
  100% {
    margin-bottom: 0px;
  }
}

@keyframes loading-cards {
  0% {
    top: 100%;
  }
  10% {
    top: calc(100% - 64px);
  }
  33% {
    top: calc(100% - 64px);
  }
  43% {
    top: calc(100% - 64px * 2);
  }
  66% {
    top: calc(100% - 64px * 2);
  }
  76% {
    top: calc(100% - 64px * 3);
  }
  100% {
    top: calc(100% - 64px * 3);
  }
}
